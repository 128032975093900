<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <Tabs v-else>
      <Tab v-for="(buyer, key) in top_suppliers" :key="key" :title="key">
        <h4 class="text-center">{{ key }}</h4>
        <hr>
        <div class="_flex _row">
          <div class="m2">
            <h5 class="text-center">Top 20 Leveranciers</h5>
            <SupplierTop100Table :table_data="buyer.top_20" :bu="bu" />
          </div>
          <div class="m2">
            <h5 class="text-center">5 Laagste Leveranciers</h5>
            <SupplierTop100Table :table_data="buyer.bottom_5" :bu="bu" />
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import SupplierTop100Table from "@/components/SupplierTop100Table.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, SupplierTop100Table, Tabs, Tab },
  data: () => ({
    top_suppliers: null,
    loading: true,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
      this.loading = true;
    },
  },
  methods: {
    getData(bu) {
      request(`top-suppliers/${bu}`, "GET").then(({ top_suppliers }) => {
        this.top_suppliers = top_suppliers;
        this.loading = false;
      });
    },
  },
};
</script>
